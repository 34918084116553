import React from 'react'
import { Box, Text, useTheme, Pressable } from 'native-base'
import { PlayTrack } from 'components/Icons'
import { usePlayer } from 'state/hooks/usePlayer'
import { useCycles } from 'state/hooks/useCycles'
import { BUCKET_ASSETS } from 'state/consts'
import { useAuth } from 'state/hooks/useAuth'
import { getNumberOfDays } from 'utils/dateHelpers'

export function IntroCard({
  showIntro,
  setShowIntro,
  setShowSubscription,
  expired,
}) {
  const {
    state: { user },
  } = useAuth()
  const { colors, ui } = useTheme()
  const {
    dispatch: { loadSound },
  } = usePlayer()
  const { state } = useCycles()
  const { cycle, day, track, steps } = state
  const baseUrl = BUCKET_ASSETS.BASE_URL_NEUROCYCLES
  return (
    <Pressable
      onPress={() => {
        if (expired) {
          setShowSubscription(true, false)
          return
        }
        setShowIntro(!showIntro)
        loadSound(
          {
            id: `cycle-${cycle}-day-${day}-track-${track}`,
            title: steps[day][track]?.title,
            description: false,
            duration: '0:00',
            uri: `${baseUrl}${day}/${steps[day][track]?.id + 1}.mp3`,
            mini: true,
            playing: true,
            suppressQuestions: true,
          },
          user
        )
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: colors.puertorico[500],
        }}
        borderRadius={ui.borderRadius}
        py={4}
        px={4}
        mb={7}
      >
        <Text variant="player" fontSize="2xl" pl={2}>
          Intro & Mini Lesson
        </Text>
        <Box>
          <PlayTrack
            width={55}
            height={55}
            outerfill={colors.tangaroa[500]}
            innerFill={colors.white[500]}
          />
        </Box>
      </Box>
    </Pressable>
  )
}
