export const DISPATCH = {
  PLAYER: {
    UPDATE: 'update',
    PAUSE: 'pause',
    PLAY: 'play',
    SET_HIDE: 'setHide',
    SET_LOADING: 'setLoading',
    TOGGLE_SIZE: 'toggleSize',
    TOGGLE_VISIBILITY: 'toggleVisibility',
    SET_VISIBLE: 'setVisible',
    SET_FULLSIZE: 'setVisibleFullSize',
    SET_SMALLSIZE: 'setVisibleSmallSize',
    TIME_UPDATE: 'timeUpdate',
    DISMISS: 'setDismiss',
  },
  AUTH: {
    GET_USER: 'getUser',
    SET_PROFILE: 'setProfile',
    SET_USER: 'setUser',
    LOGIN_USER: 'loginUser',
    REGISTER_USER: 'registerUser',
    RESET_PASSWORD: 'resetPassword',
    LOGOUT_USER: 'logoutUser',
    SET_SHOW_SUBSCRIPTION: 'showSubscription',
    SET_SUBSCRIBER_BOOL: 'setSubscriberBool',
    SET_EXPIRED: 'setExpired',
  },
  QUESTIONS: {
    LOAD: 'loadQuestionsState',
    RESET: 'resetQuestionnaire',
    SET_ANSWERS: 'setAnswers',
  },
  CYCLES: {
    ACTIVE_REACH_COMPLETED: 'activeReachCompleted',
    LOAD: 'loadCycleState',
    NEXT_TRACK: 'nextTrack',
    QUESTIONS_DONE: 'questionnaireDone',
    HIDE_VIDEO_MODAL: 'hideVideoModal',
    GATHER_UPDATE: 'gatherUpdate',
    SET_SHOW_GATHER: 'setShowGather',
    SET_SHOW_DAY_INFOCARD: 'setShowDayInfoCard',
    SET_QUESTIONNAIRES: 'setQuestionnaires',
    SET_QUESTIONNAIRES_POP: 'setQuestionnairePop',
    SET_CYCLE_HISTORY: 'setCyclesHistory',
    SET_QUESTIONS: 'setQuestions',
    SET_OPEN: 'setIsOpen',
    SHOW_VIDEO_MODAL: 'showVideoModal',
  },
  PURCHASE: {
    SET_AVAILABLE_PRODUCTS: 'setAvailableProducts',
    SET_ALL_PRODUCTS: 'setAllProducts',
    SET_PURCHASE_HISTORY: 'setPurchaseHistory',
    SET_EXPIRED: 'setExpired',
    SET_PROMOS: 'setPromos',
    SET_PREMIUM: 'setPremium',
    SET_INITIALIZED: 'setInitialized',
  },
}

export const ETHNICITY = [
  { id: 0, label: 'Caucasian', value: 'caucasian' },
  { id: 1, label: 'Black/African', value: 'black' },
  { id: 2, label: 'Hispanic/Latinx', value: 'latin' },
  { id: 3, label: 'Asian', value: 'asian' },
  { id: 4, label: 'Native American', value: 'nativeAmerican' },
  { id: 5, label: 'Pacific Islander', value: 'pacificIslander' },
  { id: 6, label: 'Prefer Not to say', value: 'n/a' },
  { id: 7, label: 'Other', value: 'other' },
]

export const AUTH_ERRORS = {
  'auth/user-not-found':
    'The credentials supplied are not correct. Please check and try again?',
  'auth/invalid-user-token':
    'Auth credentials are longer valid. Please sign in again.',
  'auth/network-request-failed':
    'Network error has occurred. Please try again later',
  'auth/operation-not-allowed': 'Auth provider not allowed.',
  'auth/requires-recent-login':
    'Last sign-in time does not meet the security threshold. Please reauth user',
  'auth/too-many-requests':
    'We detected unusual activity from this device. Trying again after some delay would unblock.',
  'auth/user-disabled':
    'This account has been disabled by an administrator. Please contact support.',
  'auth/user-token-expired': 'Auth credential has expired. Please try again',
  'auth/internal-error': 'Something went wrong, please try again later.',
  'auth/weak-password': 'The new password is too weak',
  'auth/wrong-password': 'The current password is invalid.',
}

export const REGISTER_ERRORS = {
  'auth/internal-error': 'Something went wrong, please try again later.',
  'auth/credential-already-in-use':
    'Sorry, we could not register you. Please check your credentials, and try again.',
  'auth/email-already-in-use':
    'Sorry, we could not register you. Please check your credentials, and try again.',
  'auth/age-limit': 'Sorry, you must be at least 13 years old to use this app.',
}

export const WEBVIEWS = {
  YOUTUBE: 'https://www.youtube.com/user/DrCarolineLeaf',
  BLOG: 'https://drleaf.com/blogs/news',
  STORE: 'https://drleaf.com/collections/all',
}

export const LINK_TYPES = {
  APP_LINK: 'APP_LINK',
  WEB_LINK: 'WEB_LINK',
  EMAIL: 'EMAIL',
  EXTERNAL_WEB_LINK: 'EXTERNAL_WEB_LINK',
  EXTERNAL_APP_LINK: 'EXTERNAL_APP_LINK',
}

export const APP_LINKS = {
  PLAY_STORE: {
    link: 'https://play.google.com/store/apps/details?id=com.switch_android&hl=en_US&gl=US',
    title: 'App Store',
    type: LINK_TYPES.EXTERNAL_APP_LINK,
  },
  APP_STORE: {
    link: 'https://apps.apple.com/us/app/neurocycle/id1460119837',
    title: 'Play Store',
    type: LINK_TYPES.EXTERNAL_APP_LINK,
  },
  APP_SUPPORT: {
    link: 'help@neurocycle.app',
    text: 'help@neurocycle.app',
    type: LINK_TYPES.EMAIL,
  },
  APPLE_SUPPORT: {
    link: 'https://support.apple.com/contact',
    title: 'https://support.apple.com/contact',
    type: LINK_TYPES.EXTERNAL_WEB_LINK,
  },
  BOOKS: {
    LEARN_SUCCEED: {
      link: 'https://drleaf.com/collections/all/products/think-learn-succeed-book',
      text: 'Think, Learn, Succeed.',
      type: LINK_TYPES.APP_LINK,
    },
    PERFECT_YOU: {
      link: 'https://drleaf.com/collections/all/products/the-perfect-you',
      text: 'The Perfect You',
      type: LINK_TYPES.APP_LINK,
    },
    SWITCH_ON: {
      link: 'https://drleaf.com/collections/all/products/switch-on-your-brain',
      text: 'Switch on Your Brain',
      type: LINK_TYPES.APP_LINK,
    },
  },
  GLOSSARY: {
    AMYDALA: {
      link: 'https://www.sciencedaily.com/terms/amygdala.htm',
      text: 'Science Daily',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    PARASYMPATHETIC: {
      link: 'https://www.sciencedaily.com/terms/parasympathetic_nervous_system.htm',
      text: 'Science Daily',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    NUTRITION: {
      link: '',
      text: '3 Simple Nutrition Tips to Boost Your Brain & Mental Health AND Improve Memory and Cognition!',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    BRAIN_HEALTH: {
      link: '',
      text: '5 Simple and Effective Mental Self-Care Tips Guaranteed to Reduce Anxiety in Your Life and Boost Brain Health',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    THREE: {
      link: '',
      text: 'Science Daily',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    WORST_DAY: {
      link: '',
      text: '4 Mental Strategies to Turn Your Worst Day (or Week) Around',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    REDUCE_ANXIETY: {
      link: '',
      text: '4 Tips to Drastically Reduce Anxiety in Your Life',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },

    TOXIC_STRESS: {
      link: '',
      text: '3 Signs your Body is in Toxic Stress and What You Can Do About It',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    BURNOUT: {
      link: '',
      text: 'Tips to Recognize and Prevent Mental Burnout',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    ARGUE: {
      link: '',
      text: 'Why We Need to Argue More + Tips to Argue Correctly',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    BRITANNICA: {
      link: '',
      text: 'Britannica',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    IMPROVE: {
      link: '',
      text: 'How to Improve Your Memory',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    SCIENCE: {
      link: '',
      text: 'Live Science',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    EAT: {
      link: '',
      text: 'Think and Eat Yourself Smart',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    HEALTHLINE: {
      link: '',
      text: 'Healthline',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    IMBALANCE: {
      link: '',
      text: 'The Chemical Imbalance Myth',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    TEDX: {
      link: '',
      text: "Dr. Leaf's TEDx Talk on Neuroplasticity",
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    VICTIM: {
      link: '',
      text: 'You are Not a Victim of Your Biology!',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    APA: {
      link: '',
      text: 'APA',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
    TASCIENCES: {
      link: '',
      text: 'TASCIENCES',
      type: LINK_TYPES.EXTERNAL_WEB_LINK,
    },
  },
  APP_PAGES: {
    SUBSCRIPTION_PAGE: {
      link: '',
      text: 'here',
      type: LINK_TYPES.APP_LINK,
    },
  },
}

export const BUCKET_ASSETS = {
  BASE_URL_NEUROCYCLES:
    'https://storage.googleapis.com/neurocycle.appspot.com/cycle_clips_min/day-',
}

export const FAQ_TYPES = {
  BOLD: 'BOLD',
  HEADING: 'HEADING',
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER',
  PARAGRAPH: 'PARAGRAPH',
  TABLE: 'TABLE',
  LINK: 'LINK',
}

export const FAQ_TYPES_GROUP = [
  FAQ_TYPES.HEADING,
  FAQ_TYPES.QUESTION,
  FAQ_TYPES.ANSWER,
  FAQ_TYPES.PARAGRAPH,
  FAQ_TYPES.TABLE,
  FAQ_TYPES.LINK,
]

export const LMM_SECTIONS = {
  AUTONOMY: 'Autonomy',
  AWARENESS: 'Awareness',
  TOXIC_THOUGHTS: 'Toxic Thoughts and Isolation',
  TOXIC_STRESS: 'Toxic Stress and Anxiety',
  BARRIERS: 'Barriers and Challenges',
  EMPOWERMENT: 'Empowerment and Life Satisfaction',
  SURVEY: 'Survey',
}

export const QUESTION_FACTORS = {
  AUTONOMY: 'AUTONOMY',
  AWARENESS: 'AWARENESS',
  TOXIC_THOUGHTS: 'TOXIC_THOUGHTS',
  TOXIC_STRESS: 'TOXIC_STRESS',
  BARRIERS: 'BARRIERS',
  EMPOWERMENT: 'EMPOWERMENT',
  SURVEY: 'SURVEY',
}

const BASE_SCORES_CONFIG = [
  [0, 3],
  [4, 5],
  [6, 7],
  [8, 9],
]

const REVERSED_SCORES_CONFIG = [
  [8, 9],
  [6, 7],
  [4, 5],
  [0, 3],
]

export const SCORES_CONFIG = {
  AUTONOMY: [
    [
      0,
      5,
      'Minimal',
      "Minimal autonomy means you don't feel like you have much agency over your life; you may feel a little hopeless. This is totally normal because toxic thoughts can create havoc in your mind, brain, and body. However, you are creating your own pathway to empowerment over this issue, and will feel more agency as you progress through the Neurocycle.",
    ],
    [
      6,
      8,
      'Moderate',
      'Moderate autonomy means you feel like you have a reasonable amount of agency over your life, but a lot still feels out your locus of control. That will change as you progress through the Neurocycle and increase your self-regulation.',
    ],
    [
      9,
      12,
      'Good',
      'Good autonomy means you feel like you have independence when it comes to your thoughts and actions, and feel like you have a plan in place to manage the traumas and the ups and downs of life.',
    ],
    [
      13,
      15,
      'Excellent',
      'Excellent autonomy means you are self-regulating well enough to see your personal growth; you are learning how to drive your life in the direction you want it to go.',
    ],
  ],
  AWARENESS: [
    [
      0,
      3,
      'Minimal',
      "Minimal awareness means you don't feel like you have much mindfulness and understanding over your life. You may feel a little hopeless, but this is totally normal because toxic thoughts can create havoc in your mind, brain, and body. However, you are creating your own  pathway to empowerment over this issue, and will feel more agency as you progress through the Neurocycle. ",
    ],
    [
      4,
      5,
      'Moderate',
      'Moderate awareness means you feel like you have a reasonable amount of agency over your life, but a lot still feels out your locus of control. That will change as you progress through the Neurocycle and increase your self-regulation.',
    ],
    [
      6,
      7,
      'Good',
      'Good awareness means you feel like you have independence when it comes to your thoughts and actions, and feel like you have a plan in place to manage the traumas and the ups and downs of life.',
    ],
    [
      8,
      9,
      'Excellent',
      'Excellent awareness mean you are self-regulating well enough to see your personal growth; you are learning how  to drive your life in the direction you want it to go. ',
    ],
  ],
  TOXIC_THOUGHTS: [
    [
      0,
      3,
      'Excellent',
      'Excellent mind-management of toxic thoughts and isolation means you are catching the toxic thoughts and efficiently embracing, processing, and reconceptualizing them, as well as taking active steps regarding the isolation that toxic thoughts can trigger — you are doing this effectively about 80% of the time. This also means you are  self-regulating and mind-managing toxic issues as they arise, as well as dealing with suppressed traumas of the past well. You are also good at managing your use of technology, and are comfortable being alone. ',
    ],
    [
      4,
      5,
      'Good',
      'Good mind-management of toxic thoughts and isolation means you are catching the toxic thoughts and efficiently embracing, processing, and reconceptualizing them, as well as taking active steps regarding the isolation that toxic thoughts can trigger — you are doing this effectively about 60% of the time. This also means you are  self-regulating and mind-managing toxic issues as they arise, as well as dealing with suppressed traumas of the past fairly well. You are also good at managing your use of technology, and are relatively comfortable being alone. ',
    ],
    [
      6,
      7,
      'Moderate',
      'Moderate mind-management of toxic thoughts and the tendency to isolate means you are starting to embrace disruptive toxic thoughts, patterns, and isolation with the view of “you  showing up like this because of something that has happened to me—this is not who I am but who I have become, so if I wired it in as a response, I can wire it out." This is what you are learning to do with the Neurocycle. ',
    ],
    [
      8,
      9,
      'Minimal',
      'Minimal mind-management of toxic thoughts and isolation means that your thoughts run rampant, and you struggle with ruminating and overthinking to the point where you potentially dislike yourself and isolate as a coping mechanism, which is not sustainable and can make you unhappier. Recognizing this is a wonderful place to be because it means you are recognizing what isn’t working in your life, which is where the healing journey begins. Your mind, brain, and body are sending you these signals as guides or messengers to help you restore balance. ',
    ],
  ],
  TOXIC_STRESS: [
    [
      0,
      3,
      'Excellent',
      'Excellent mind-management of toxic stress and anxiety means your thought detective skills have really improved, and you are around 80% more proficient at deconstructing and reconstructing the big issues from your past, as well as the day-to-day challenges you face.',
    ],
    [
      6,
      7,
      'Good',
      'Good mind-management of toxic stress and anxiety means that you are getting the hang of being a thought detective-you are tuning into your warning signals and tracking back to the origin story.',
    ],
    [
      4,
      5,
      'Moderate',
      "Moderate mind-management of toxic stress and anxiety means you are starting to see that it's okay to be a mental mess, and that you are getting into the process of embracing, processing and reconceptualizing your thinking. However, this is challenging and takes time, which can be frustrating. This is a major lifestyle change you are making, so be kind to yourself.",
    ],
    [
      8,
      9,
      'Minimal',
      'Minimal mind-management of toxic stress and anxiety often means that you feel burnt out, overwhelmed, anxious, depressed, and a bunch of other things. But this is no problem because it is okay to be a mess at times; we all battle with mental messes because life is filled with adversity. All these feelings are warning signals that something is going on in your life. There is a reason that you feel this way; this is very normal and  why you are using this app — to repair the mess and grow. We can’t grow if we don’t recognize that we have a messy mind.',
    ],
  ],
  BARRIERS: [
    [
      8,
      9,
      'Excellent',
      'Excellent mind-management of barriers and challenges means you are getting more proficient (up to 80%) at using the different emotions a barrier or challenge in your life is activating to help you get another perspective and see more possibilities, which is also associated with higher psychological well-being, better cognitive functioning, brain energy balance, and increased volume in the brain areas that are involved in memory and learning.',
    ],
    [
      6,
      7,
      'Good',
      'Good mind-management of barriers and challenges means you are getting more proficient (up to 60%) at using the different emotions a barrier or challenge in your life is activating to help you get another perspective and see more possibilities, which is also associated with higher psychological well-being, better cognitive functioning, better brain energy balance, and increased volume in the brain areas that are involved in memory and learning.',
    ],
    [
      4,
      5,
      'Moderate',
      'Moderate mind-management of barriers and challenges means that you may feel choked or even a little despondent by the issues you are working on and the circumstances that have happened to you or that are happening to you. This is a completely normal response to adverse experiences, especially when they have been accumulating. You are probably feeling a mix of emotions as you face the barrier/challenge. However, this awareness means you are on the way to reconceptualizing this issue in your life. You are starting to see the message in the messiness of the emotions, and maybe even a glimmer of a way through — keep doing the Neurocycle!',
    ],
    [
      0,
      3,
      'Minimal',
      'Minimal mind-management of barriers and challenges means that you are probably feeling blocked, stuck or maybe even hopeless by things that have happened to you or that are happening to you. This generally results in a broad spectrum of emotions that can feel very negative and overwhelming, but is actually an adaptive response that, believe it or not, can be beneficial to your mental health because it means you are potentially at the start of processing what is going on! So, feeling a mix of, for example, worthlessness, nerves, restlessness, fear, hopelessness, shame, frustration,and sadness as you face the barrier means you are starting the process of healing, as painful as it may be. You are realizing that you need to find a way to reconceptualize what has happened in order to cope. This mess of emotions can springboard you into healing as you make them work for you, as you will learn do as you use the Neurocycle. ',
    ],
  ],
  EMPOWERMENT: [
    [
      0,
      3,
      'Minimal',
      "Minimal empowerment and life satisfaction means you don't feel like you have much mindfulness and understanding over your life. You may feel a little hopeless, but this is totally normal because toxic thoughts can create havoc in your mind, brain, and body. However, you are creating your own  pathway to empowerment over this issue, and will feel more agency as you progress through the Neurocycle. ",
    ],
    [
      4,
      5,
      'Moderate',
      'Moderate empowerment and life satisfaction means you feel like you have a reasonable capacity to manage and deal with the issues in your life. You may even feel a little hopeless; this is totally normal because toxic thoughts can create havoc in our mind, brain, and body. However, you are starting the healing journey, and will feel you are on a pathway to empowerment as you progress through the Neurocycle. ',
    ],
    [
      6,
      7,
      'Good',
      'Good empowerment and life satisfaction means you feel like you have independence when it comes to your thoughts and actions, and feel like you have a plan in place to manage the traumas and the ups and downs of life.',
    ],
    [
      8,
      9,
      'Excellent',
      'Excellent empowerment and life satisfaction means you are self-regulating well enough to see your personal growth; you are learning how to drive your life in the direction you want it to go. ',
    ],
  ],
}

export const CALENDAR_STYLES = {
  LIGHT_CONTENT: 'LIGHT_CONTENT',
  DARK_CONTENT: 'DARK_CONTENT',
}

export const INPUT_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
  PASSWORD: 'password',
  RADIO: 'radio',
  SELECT: 'select',
}

export const KEYBOARD_TYPES = {
  EMAIL: 'email-address',
}

export const TEXT_CONTENT_TYPES = {
  EMAIL: 'emailAddress',
  PASSWORD: 'password',
  NEW_PASSWORD: 'newPassword',
}

export const PLAYLIST_GROUPS = {
  MINI_NEUROCYCLES: 'mini-neurocycles',
  DECOMPRESSION_TIPS: 'decompression-tips',
  PARENT_NEUROCYCLES: 'parent-neurocycles',
}

export const NOTIFICATION_STATUS = {
  NEW: 'NEW',
  SEEN: 'SEEN',
  DELETED: 'DELETED',
}

export const RESOURCE_VIDEOS = [
  {
    uniqueKey: 0,
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-0.jpg',
    title: 'Welcome',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos_min%2FDrLeaf_AppVideo_720p_5_UpdatedColorGrade-min.mp4?alt=media&token=e7a749b0-53b5-4005-ba5f-a7b127796c66',
    type: 'video',
  },
  {
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-1.jpg',
    uniqueKey: 1,
    title: 'The Neurocycle',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos_min%2FDrLeaf_AppVideo_720p_2_UpdatedColorGrade-min.mp4?alt=media&token=6c2f7f3b-a9a2-49cf-8799-54b83019d007',
    type: 'video',
  },
  {
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-2.jpg',
    screenshotTimestamp: 6000,
    uniqueKey: 2,
    title: 'Why 63 Days',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos%2FDrLeaf_AppVideo_720p_3_UpdatedColorGrade.mp4?alt=media&token=8f67deea-ca99-4080-9204-9c56ad9c3b90',
    type: 'video',
  },
  {
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-3.jpg',
    screenshotTimestamp: 41000,
    uniqueKey: 3,
    title: 'The Metacog',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos_min%2FDrLeaf_AppVideo_720p_1_UpdatedColorGrade-min.mp4?alt=media&token=abf2cda1-7059-4657-b011-904481f30c9e',
    type: 'video',
  },
  {
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-4.jpg',
    screenshotTimestamp: 5500,
    uniqueKey: 4,
    title: 'End of 21 Days',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos_min%2FDrLeaf_AppVideo_720p_4_UpdatedColorGrade-min.mp4?alt=media&token=c31c1981-487f-446a-977f-91791817ed64',
    type: 'video',
  },
  {
    img: 'https://storage.googleapis.com/neurocycle.appspot.com/app_images/video-5.jpg',
    uniqueKey: 5,
    title: 'What is a thought',
    url: 'https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/intro_videos_min%2FDrLeaf_AppVideo_720p_6_UpdatedColorGrade-min.mp4?alt=media&token=d8687553-6fff-4eeb-b65f-a22128e75ddd',
    type: 'video',
  },
]

export const PROMO_VIDEOS = {
  KIDBOOK_PHONE: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/promo/KidsBook_Phone.mp4',
  },
  KIDBOOK_REGULAR: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/promo/KidsBook_Regular.mp4',
  },
}

export const FIREBASE_COLLECTIONS = {
  CYCLES: 'cycles',
  HISTORY: 'audio-history',
  HISTORY_CYCLE: 'cycle-history',
  NOTIFICATIONS: 'notifications',
  REMINDERS_CRON: 'reminders-cron',
  USERS: 'users',
  NEUROLIVERESOURCES: "NeurolivesResources"
}

export const REMOTE_BGS = {
  STOREBTN_BG: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/store.png'
  },
  FACILITATORBTN_BG: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/facilitator.png'
  },
  PODCASTBTN_BG: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/podcasts.png'
  },
  TWENTY_ONE: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/continue21_2x.png',
  },
  MINI_CYCLES: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/minicycles_2x.png',
  },
  DECOMPRESSION_TIPS: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/restart21_2x.png',
  },
  PODCASTS_TEASER: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/podcast_2x.png',
  },
  PARENT_CYCLES: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/parentneuro.png',
  },
  BLUE_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/BlueThumbnail.png',
  },
  GREEN_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/GreenThumbnail.png',
  },
  ORANGE_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/OrangeThumbnail.png',
  },
  PINK_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/PinkThumbnail.png',
  },
  RED_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/RedThumbnail.png',
  },
  YELLOW_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/YellowThumbnail.png',
  },
  DRLEAF_THUMBNAIL: {
    uri: 'https://storage.googleapis.com/neurocycle.appspot.com/app_bgs/newThumb.png'
  }
}

export const COUNTRIES = [
  { name: 'United States', code: 'US' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
]

export const PLAYER_FULL_INIT = {
  id: false,
  title: false,
  description: false,
  duration: false,
  uri: false,
  mini: false,
  playing: false,
  visible: true,
  maximum: true,
}

export const EXTRA_LMM_QUESTIONS = [
  {
    _meta: {
      id: 'YlXJpxIAAJQcps17',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my knowledge of warning signals of anxiety and depression.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps18',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my self-perception of toxic thoughts and their origin story.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps19',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my resilience.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps20',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my gratitude.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps21',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my autonomy.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps22',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my hope.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps23',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my confidence.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
  {
    _meta: {
      id: 'YlXJpxIAAJQcps24',
    },
    type: 'SURVEY',
    question: [
      {
        type: 'paragraph',
        text: 'The Neurocycle process increases my behavior to think, feel, and choose positive mental perspectives autonomously.',
        spans: [],
      },
    ],
    __typename: 'Questionnaire',
  },
]
